import { createAnalyticsDispatch, createInitAnalyticsUser, initAnalytics } from "@planckdata/react-components"
import { ENV } from "env"
import _mixpanel from "mixpanel-browser"

const mixpanel = initAnalytics(_mixpanel, "lead-gen-fe", ENV.MIXPANEL_API_KEY)

export const dispatchTrackEvent = createAnalyticsDispatch(mixpanel, "lead-gen-fe")

export const initAnalyticsUser = createInitAnalyticsUser(mixpanel, "lead-gen-fe")

export enum Events {
  SelectZipCode = "Zipcode selection",
  RemoveZipCodeFromChip = "Removing zipcode from chip",
  RemoveZipCodeFromCheckbox = "Removing zipcode from checkbox",
  SelectNAICS = "NAICS selection",
  RemoveNAICSFromChip = "Removing NAICS from chip",
  RemoveNAICSFromCheckbox = "Removing NAICS from checkbox",
  SegmentDefinitionFail = "Failed segment definition",
  SegmentDefinitionSuccess = "Successful segment definition",
  TerritoryDefinitionFail = "Failed territory definition",
  TerritoryDefinitionSuccess = "Successful territory definition",
  RiskFactorsProceedFail = "Failed risk factors definition",
  RiskFactorsProceedSuccess = "Successful risk factors definition",
  // RiskFactorsProceedGoBack = "Risk factors go back",
  ChangeRiskFactors = "Filters selection",
  ChangeAdditionalInfo = "Additional Info selection",
  ToggleAllAdditionalInfo = "Additional Info toggle all",
  GenerateLeadsSuccess = "Failed leads generation",
  GenerateLeadsFail = "Successful leads generation",
  ResultsView = "Results view",
  ImportLeadsLiked = "Import Leads Liked",
  SeeMoreClick = 'Successful "see more"',
  SeeMoreClose = "Close see more",
  SeeMoreFail = 'Failed "see more"',
  ResultsTableBusinessNameClick = "Business name",
  ResultsTableBusinessNameClickFail = "Failed business name",
  // ChangeResultsPage = "Results page selection",
  ChangeLeadsSelection = "Leads selection",
  ExportLeadsSuccess = "Successful leads export",
  ExportLeadsFail = "Failed leads export",
  PhoneIconClick = "Phone icon click",
  PhoneIconHover = "Phone icon hover",
  WebsiteIconClick = "Website icon",
  AdditionalInfoTabClick = "Single business additional info tab opened",
  RiskFactorsTabClick = "Single business risk info tab opened",
  SidebarBusinessNameClick = "Single business name link",
  LogoClick = "Logo clicked",
  // TargetMarketStepClick = "Target Market navigation",
  // RiskFactorsStepClick = "Risk Factors navigation",
  ResultsStepClick = "Results navigation",
  NewSearchClick = "Trigger New Search",
  NewSearchDontShowAgainClick = "Don't show again message",
  NewSearchConfirm = "Run new search",
  NewSearchCancel = 'Cancel "run new search"',
  LeadFeedback = "Business feedback",
  NewSearchFromError = "New search from error",
  SupportEmailClick = "Support email click",
  LocationSearch = "Location search",
  SegmentSearch = "Segment search",
  CollapseZipGroup = "Collapse zip Group",
  ExpandZipGroup = "Expand zip Group",
  CollapseNAICSGroup = "Collapse NAICS Group",
  ExpandNAICSGroup = "Expand NAICS Group",
  ClearAllZipcodes = "Clear All Zipcodes",
  ClearAllNAICS = "Clear All NAICS",
  GoBackSuccess = "Successful going back",
  SidebarGroupExpand = "Expanding sidebar group",
  SidebarGroupCollapse = "Collapsing sidebar group",
  RiskFactorsSearchFilter = "Insight search on risk factor screen",
  RiskFactorsPackageFilter = "Package selection on risk factor screen",
  AdditionalInfoSearchFilter = "Insight search on additional info screen",
  AdditionalInfoPackageFilter = "Package selection on additional info screen",
  HoverInsightDescription = "Insight description",
  GenerateLeadsMaxChange = "Leads results limit",
  ResultCountHover = "Total number check",
  ExpandNAICSList = "NAICS expansion",
  SidebarMapDrag = "Map drag",
}
