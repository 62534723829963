import "./env"
import { i18nInit, i18nNamespace } from "./i18n"
import { GlobalStyle } from "@planckdata/react-components"
import AppLoader from "components/pages/App/AppLoader"

import React from "react"
import ReactDOM from "react-dom"
import App from "./components/pages/App"
import reportWebVitals from "./reportWebVitals"

/// WARNING
/// This file is not included in the final bundle, so anything you add here is for developer mode use only.
/// If you put any init code here, make sure it is also running elsewhere; e.g. being exported to run from the consumer,
/// or running as part of an exported module

i18nInit([i18nNamespace, "react-components"], ["en-US", "de-DE"])

ReactDOM.render(
  // TODO: removed until @material-ui supports Strict Mode (v5)
  // <React.StrictMode>
  <React.Suspense fallback={<AppLoader />}>
    <GlobalStyle />
    <App />
  </React.Suspense>,
  // </React.StrictMode>,
  document.getElementById("root"),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
