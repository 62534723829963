import { LoginFormProps, useTracking } from "@planckdata/react-components"
import React from "react"
import { useHistory } from "react-router"
import { useMount } from "react-use"
import { Routes } from "router"
import { useUser } from "../../../../user-context"

export type UseLoginResponse = Omit<LoginFormProps, "mode">

export function useLogin(
  loading: boolean,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
): UseLoginResponse {
  const user = useUser()

  useMount(() => {
    user
      .initializeClient()
      .catch(console.error)
      .then(() => setLoading(false))
  })

  const loginProps: UseLoginResponse = {
    onLogin: async (details) => {
      await user.requestLogin(details)
      setLoading(false)
    },
    onRegister: async () => console.warn("Not implemented: Register"),
    onResetPassword: async (details) => {
      const res = await user.requestPasswordChange(details)
      setLoading(false)
      return res
    },
    isLoggedIn: user.isLoggedIn,
    loading: loading,
    title: false,
    routes: {
      home: Routes.Home,
      login: Routes.Login,
      passwordReset: Routes.ChangePassword,
      register: Routes.Register,
    },
  }
  return loginProps
}

export function usePageView(): void {
  const { trackEvent } = useTracking()
  const history = useHistory()

  React.useEffect(() => {
    trackPageView()
    return history.listen(trackPageView)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history])

  function trackPageView() {
    trackEvent({
      action: "Page View",
      path: window.location.pathname,
      domain: window.location.host,
    })
  }
}
