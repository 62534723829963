import React from "react"
import {
  Logo,
  makeStyles,
  ElementComponentProps,
  Box,
  Grid,
  FontFamily,
  alpha,
  colors,
  typography,
} from "@planckdata/react-components"
import { I18nNSContext } from "@planckdata/i18n"
import BackgroundImage from "./background.jpg"
import { useTranslation } from "i18n"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100vw",
    minHeight: "100vh",
    background: `url(${BackgroundImage})`,
    backgroundSize: "cover",
  },
  formContainer: {
    height: "100vh",
    backgroundColor: alpha(colors.contrastText, 0.6),
    backdropFilter: "blur(38px)",
    borderRight: `1px solid ${colors.contrastText}`,
    padding: theme.spacing(11, 10),
    flexBasis: "auto",
  },
  planckLogo: {
    opacity: 0.2,
  },
  innerContainer: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    ...typography({ family: FontFamily.Khand, weight: 300, size: 56 as any, lineHeight: "1" }),
    marginLeft: -3,
    textAlign: "start",
    width: "100%",
  },
  subtitle: {
    ...typography({ weight: 300, size: 16, lineHeight: "26px" }),
    color: alpha("#2E2F30", 0.7),
    marginTop: theme.spacing(3),
    width: "100%",
  },
}))

export const AuthLayout: React.FC<ElementComponentProps<HTMLDivElement>> = ({ children, ...props }) => {
  const classes = useStyles(props)
  const { t } = useTranslation()
  return (
    <I18nNSContext.Provider value="react-components">
      <Grid container className={classes.root}>
        {/* <Grid item className={classes.formContainer} xs={12} sm={8} md={6} lg={4} xl={3}> */}
        <Grid item className={classes.formContainer}>
          <Logo className={classes.planckLogo} />
          <div className={classes.innerContainer}>
            <div className={classes.title}>{t("login.title")}</div>
            <div className={classes.subtitle}>{t("login.subtitle")}</div>
            <Box height={64} />
            {children}
          </div>
        </Grid>
        <Grid item xs />
      </Grid>
    </I18nNSContext.Provider>
  )
}

export default AuthLayout
