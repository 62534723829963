import { Redirect, Route, RouteProps } from "react-router"
import React from "react"
import { observer } from "mobx-react-lite"
import { LoadingContainer } from "@planckdata/react-components"
import AppLoader from "../AppLoader"
import { useUser } from "../../../../user-context"

export const PrivateRoute: React.FC<RouteProps> = observer((props) => {
  const user = useUser()
  return (
    <LoadingContainer loading={Boolean(!user) || Boolean(!user.firstLoggedIn)} loader={<AppLoader />}>
      {user.isLoggedIn ? <Route {...props} /> : <Redirect to={{ pathname: "/login" }} />}
    </LoadingContainer>
  )
})

export default PrivateRoute
