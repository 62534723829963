import { CurrentUserResponse } from "@planckdata/api"
import { setupUserContext } from "@planckdata/storage"
import { dispatchTrackEvent, initAnalyticsUser } from "./analytics"

interface UserInfoResponse extends CurrentUserResponse {
  availableProperties: AvailableProperties
}

export interface AvailableProperties {
  zip_codes: Array<string>
  additional_info: Array<string>
  risk_factor_filters: Array<string>
  naics_codes: Array<string>
  max_leads: number | null
}

export interface User extends CurrentUserResponse {
  availableProperties: AvailableProperties
}

function mapUser(user: CurrentUserResponse, userResponse: UserInfoResponse): User {
  const { availableProperties } = userResponse
  return {
    ...user,
    availableProperties,
  }
}

const { useUser, userStore, UserProvider, UserConsumer } = setupUserContext({
  storeConfig: {
    mapUser,
    dispatchTrackEvent,
    initAnalyticsUser,
  },
})

export { useUser, userStore, UserProvider, UserConsumer }
