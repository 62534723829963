import React from "react"
import { hot } from "react-hot-loader/root"
import { BrowserRouter } from "react-router-dom"
import { observer } from "mobx-react-lite"
import { planckTheme, ThemeProvider, track } from "@planckdata/react-components"
import { setAuthorizationToken } from "@planckdata/api"
import { I18nNSContext } from "@planckdata/i18n"
import { dispatchTrackEvent } from "analytics"
import AppRouter from "./AppRouter"
import AppLoader from "./AppLoader"
import { i18nNamespace, useTranslation } from "../../../i18n"
import { getHostingEnv } from "@planckdata/typescript-utils"
import { UserProvider, useUser } from "../../../user-context"
import { QueryClient, QueryClientProvider } from "react-query"

const App: React.FC = track(
  { environment: getHostingEnv() },
  { dispatch: dispatchTrackEvent },
)(
  observer(() => {
    const { i18n } = useTranslation()
    const user = useUser()
    const userOrgType = user?.current?.orgType
    const isUserInternal = user?.current?.internal
    const queryClient = new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnMount: false,
          refetchOnWindowFocus: false,
          staleTime: Infinity,
        },
      },
    })

    React.useEffect(() => {
      if (user.current?.locale != null) {
        i18n.changeLanguage(user.current?.locale)
      }
    }, [i18n, user, user.current?.locale])

    return (
      <QueryClientProvider client={queryClient}>
        <I18nNSContext.Provider value={i18nNamespace}>
          <React.Suspense fallback={<AppLoader />}>
            <BrowserRouter>
              <UserProvider>
                <ThemeProvider theme={planckTheme}>
                  <AppRouter isPilotUser={userOrgType === "poc" && !isUserInternal} />
                </ThemeProvider>
              </UserProvider>
            </BrowserRouter>
          </React.Suspense>
        </I18nNSContext.Provider>
      </QueryClientProvider>
    )
  }),
)
export default hot(App)
