import { getHostingEnv } from "@planckdata/typescript-utils"

export const ENV = {
  AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN!,
  AUTH0_CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID!,
  AUTH0_AUDIENCE: process.env.REACT_APP_AUTH0_AUDIENCE!,
  MIXPANEL_API_KEY: process.env.REACT_APP_MIXPANEL_API_KEY!,
  MIXPANEL_DEBUG: window.localStorage.getItem("__mp_debug__") === "true",
  GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
  VERSION: process.env.REACT_APP_VERSION!,
}

if (getHostingEnv() === "production") {
  const c: any = console
  const noop = () => null
  c.debug = noop
  c.log = noop
  c.info = noop
  c.table = noop
  c.group = noop
  c.groupCollapsed = noop
  c.groupEnd = noop
}
