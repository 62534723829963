import React, { lazy, Suspense, useState } from "react"
import { Route, Switch } from "react-router-dom"
import { Routes } from "router"
import { LoginForm, LoginFormMode } from "@planckdata/react-components"
import AuthLayout from "components/layouts/AuthLayout"
import { usePageView, useLogin } from "./router-hooks"
import AppLoader from "../AppLoader"
export interface AppRouterProps {
  isPilotUser: boolean
}

const AppRouter: React.FC<AppRouterProps> = ({ isPilotUser }) => {
  const [loading, setLoading] = useState(true)
  const loginProps = useLogin(loading, setLoading)

  usePageView()

  return (
    <Suspense fallback={<AppLoader />}>
      <Switch>
        {/* Login routes - need different layout */}
        <Route
          path={Routes.Login}
          exact
          render={() => (
            <AuthLayout>
              <LoginForm {...loginProps} title={false} />
            </AuthLayout>
          )}
        />
        <Route
          path={Routes.Register}
          exact
          render={() => (
            <AuthLayout>
              <LoginForm {...loginProps} mode={LoginFormMode.Register} />
            </AuthLayout>
          )}
        />
        <Route
          path={Routes.ChangePassword}
          exact
          render={() => (
            <AuthLayout>
              <LoginForm {...loginProps} mode={LoginFormMode.ChangePassword} />
            </AuthLayout>
          )}
        />
        <Route
          path="*"
          render={() => {
            const LeadGenRoutes = lazy(() => import("./LeadGenRoutes"))
            return <LeadGenRoutes isPilotUser={isPilotUser} />
          }}
        />
      </Switch>
    </Suspense>
  )
}

export default AppRouter
